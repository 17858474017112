import { Line, mixins } from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      required: false,
      type: Object,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scaleBeginAtZero: false,
      }),
    },
  },
  mounted() {
    // @ts-ignore
    Chart.plugins.register(chartjsPluginAnnotation);
    // @ts-ignore
    this.addPlugin(chartjsPluginAnnotation);
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  },
};
